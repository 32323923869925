.wallet {
  /* --bg-color: #ceb2fc; */
  --bg-color-light: #0756b7;
  --text-color-hover: #fff;
  --box-shadow-color: rgba(35, 183, 247, 0.48);
}

.card {
  width: 150px;
  height: 200px;
  background: #fff;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-out;
  text-decoration: none;
}

.card:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px var(--box-shadow-color);
}

.card:hover .overlay {
  transform: scale(4) translateZ(0);
}

.card:hover .circle {
  border-color: var(--bg-color-light);
  background: var(--bg-color);
}

.card:hover .circle:after {
  background: var(--bg-color-light);
}

.card:hover p {
  color: var(--text-color-hover);
}

.card p {
  /* font-size: 17px; */
  letter-spacing: 0.5px;
  color: #4c5656;
  margin-top: 30px;
  z-index: 1;
  transition: color 0.3s ease-out;
}

.circle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
}

.circle:after {
  content: "";
  width: 70px;
  height: 70px;
  display: block;
  position: absolute;
  background: var(--bg-color);
  border-radius: 50%;
  top: 0px;
  left: 0px;
  transition: opacity 0.3s ease-out;
}

.circle svg {
  z-index: 1;
  /* transform: translateZ(0); */
}

.overlay {
  width: 118px;
  position: absolute;
  height: 118px;
  border-radius: 50%;
  background: var(--bg-color);
  top: 70px;
  left: 50px;
  z-index: 0;
  transition: transform 0.3s ease-out;
  transform: scale(0); /* Initially hidden */
}