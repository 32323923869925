.cardContainer {
    width: 300px;
    height: 300px;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }

  .card {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }

  .card .frontContent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }

  .card .frontContent p {
    font-size: 32px;
    font-weight: 700;
    opacity: 1;
    text-align: center;
    background: linear-gradient(to bottom, #29a8de, #2978a0);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1); */
  }

  .card .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    background: linear-gradient(to bottom, #29a8de, #2978a0);
    color: #e8e8e8;
    padding: 20px;
    line-height: 1.5;
    border-radius: 5px;
    pointer-events: none;
    transform: translateX(-96%);
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }

  .card .content .heading {
    font-size: 32px;
    font-weight: 700;
  }

  .card:hover .content {
    transform: translateY(0);
  }

  .card:hover .frontContent {
    transform: translateX(-30%);
  }

  .card:hover .frontContent p {
    opacity: 0;
  }
