@tailwind base;

@tailwind components;

@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Monoton&family=Share+Tech&family=Ubuntu:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sedgwick+Ave&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Phudu&family=Sedgwick+Ave&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.5px;
}

.loading-indicator:before {
  content: '';
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000000000;
}

.loading-indicator:after {
  content: 'Loading...';
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1000000001;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

/* YourComponent.css */

/* YourComponent.css */

/* YourComponent.css */

@keyframes typing {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

.typing-effect {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 6s steps(30, end) infinite; /* Added 'infinite' to repeat the animation */
  animation-fill-mode: forwards;
  border-right: 2px solid transparent;
}

.typing-effect::after {
  content: '';
  display: inline-block;
  vertical-align: bottom;
  /* width: 0.3em; */
  height: 1em;
  background-color: white;
  animation: cursor 0.8s infinite;
}

@keyframes cursor {
  from,
  to {
    background-color: white;
  }
  50% {
    background-color: transparent;
  }
}


/* Add this CSS to your global styles or a dedicated CSS file */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
