/* OurCredentialCard.css */
.credential-card {
  position: relative;
  background-color: #1f2937;
  border-radius: 10px;
  padding: 20px;
  opacity: 1;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  overflow: hidden;
}

.credential-card:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 50%, rgba(18, 60, 97, 0.1) 50%),
              linear-gradient(-45deg, transparent 50%, rgba(255, 255, 255, 0.1) 50%);
  background-size: 20px 20px;
  z-index: -1;
}

.credential-card:hover {
  transform: scale(1.05);
}

.credential-card ul li {
  opacity: 1;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.credential-card:hover ul li {
  opacity: 1;
  transform: translateY(0);
}

.credential-card h4 {
  opacity: 1;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.credential-card:hover h4 {
  opacity: 1;
  transform: translateY(0);
}
